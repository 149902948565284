'use strict';

// カーソルが左上から飛んでくるのを防ぐために遅延表示
// const VISIBLE_CURSOR_DELAY = 1000;
//
// $(function () {
//   setTimeout(() => {
//     $('#cursor').css({opacity: 1});
//   }, VISIBLE_CURSOR_DELAY);
// });

$(function () {
  // menuボタンの表示・非表示制御
  const glNav = document.getElementById('glNav');
  const menuButton = document.getElementById('menuButton');
  if(glNav === null) return;
  menuButton.addEventListener('click', function (e) {
    e.preventDefault();

    if (glNav.classList.contains('is_show')) {
      glNav.classList.remove('is_show');
    } else {
      glNav.classList.add('is_show');
    }

  });
});

$(function () {
  const submitButton = document.getElementById('submit');
  const sentCloseButton = document.getElementById('sentCloseButton');
  const sentMail = document.getElementById('sentMail');
  const form = document.getElementById('form');
  if(submitButton === null) return; // お問合せフォームの送信ボタンが無ければ対象外ページとみなして早期リターン
  submitButton.addEventListener('click', function (e) {
    let params = new URLSearchParams();
    $(form).find('[name]').each(function (index, element) {
      const name = $(element).attr('name');
      params.append(name, form[name].value);
    });
    axios.post('/mail.php', params)
      .then(response => {
        sentMail.classList.add('is_show');
      }).catch(error => {
      console.log(error);
    });
  });
  sentCloseButton.addEventListener('click', function (e) {
    e.preventDefault();
    sentMail.classList.remove('is_show');
  });
});